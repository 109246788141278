#dropdown_container>div{
   text-align: left;
   font-size: 12px;
   color: grey;
}
#loader_funnel {
    height: 30vh;
    width: 50vh;
    /* height: 300px;
    width: 600px; */
    object-fit: cover;
    margin: auto;
    margin-top: 7em;
    pointer-events: none;
  }
  #status_container{
    /* border:1px solid black; */
    display: flex;
    gap:20px;
    margin-top: 20px;
  }
  #info_icon_contain{
    width: 4vh;
    height:4vh;
    border-radius: 50%;
    position: absolute;
    right: 0;
    /* bottom: 0; */
    background-color: #007cc3;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 20px;
    bottom: 0;
    z-index: 999;
    margin-bottom: 10px;
  }
  #process_contain{
    width: 400px;
    max-height: 400px;
    padding: 5px;
    border-radius: 10px;
    font-size: 14px;
  }
  #status_container>div{
   display: flex;
   flex-direction: row;
   align-items: center;
   font-size: 16px;
   max-width: 200px;
  }
  #status_container>div>span{
    margin-left: 5%;
  }
  #status_container>div>div{
   /* border:1px solid black; */
  height:20px;
  width:20px;
  }

  #leades_header{
    margin-right: 20%;
    font-size: 20px;
    font-weight: 500;
  }
  #all_lead_status{
    display: flex;
    gap:20px;
    margin-top: 20px;
  }
  #all_lead_status>div{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
   }  
   #all_lead_status>div>span{
     margin-left: 5%;
   }
   #all_lead_status>div>div{
    /* border:1px solid black; */
   height:20px;
   width:20px;
   }
   #change_btn{
    height:30px;
    font-size: 13px;
    padding: 5px 10px;
   }

#dropdown_container>div select{
    /* width: 500px; */
}


@media all and (max-width: 4000px) and (min-width: 1800px) {
  #status_container>div{   
    font-size: 20px;
   }
}