#ceo,#ceo1 {
  border: 1px solid rgb(201, 201, 201);
    background-color: white;
    padding-top: 10px  !important;
    padding-bottom: 10px !important;
    text-align: center !important;
  }
  #th_unit{
    border: 1px solid white;
  } 

  #th_unit_body{
    border: 1px solid rgb(201, 201, 201);
    padding-top: 10px  !important;
    padding-bottom: 10px !important;
    text-align: center !important;
  }

@media all and (max-width: 1400px ) and (min-width: 1000px){
#loader{
    height: 70vh;
    width: 70vh;
}
}