#count_contain{
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 50px;
}
#count_contain>div{
    padding: 10pX;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

#count_contain>div p{
    color: grey;
    font-size: 15px;
} 

#count_contain>div h1{
    color: #444a4f;
    /* font-size: 45px; */
} 
