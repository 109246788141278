#header{
    margin-top: 13vh;
    color: #3185c6
}
#footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: grey;
    text-align: center;
    z-index: 1;
    background-color: white;
    height: 4.5%
}
#img_contain{
}
#img_contain>img{
    height:67vh;
    /* border:1px solid red; */
    margin-left: 12.5%;
    margin-top: 100px;
}
#form_contain{
    /* border:1px solid black; */
    /* width: 70%; */
    margin: auto;
    padding: 2vh 9.8vw;
}
#form_contain>div{
    text-align: left;
    margin: auto;
}
#form_contain button{
    margin-left: auto;
    margin-right: auto;
    display: block;
    outline: none;
    /* background-color: #466543;
    border:1px solid #466543; */
    background-color: #3488c8;
    border:1px solid #3488c8;
}
#form_contain label>span{
    color:red;
}
#logo_pic{
    height: 50px;
    margin-left: 2em;
}

#header_logo{
    display: flex;
    display: flex;
    justify-content: left;
    gap:20px;
    margin-top: 2em;

    /* border: 1px solid red; */
}
#header_logo>h2{
    margin-top: 1em;
}
.vl{
    border-left: 3px solid grey;
    height: 60px;
    margin-top: 1em;
}
#left_part{
    height: 100vh;
    background-color: #ebf2fa;
}

#info_icon_contain{
    width: 4vh;
    height:4vh;
    border-radius: 50%;
    position: fixed;
    right: 0;
    /* bottom: 0; */
    background-color: #007cc3;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 20px;
    bottom: 0;
    z-index: 999;
    margin-bottom: 10px;
  }
  #process_contain{
    width: 400px;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px;
    border-radius: 10px;
    font-size: 14px;
  }

@media all and (max-width: 1350px) and (min-width: 760px) {
    #img_contain>img{
       object-fit: contain;
       /* margin-left: 0%; */
    }
}
  
   
@media all and (max-width: 759px) and (min-width: 50px) {
    #img_contain>img{
        object-fit: contain;
        margin-left: 0%;
     }
  }