#tabs_container{
    border:1px solid rgb(211, 210, 210);
}
#oh_tab{
    cursor: pointer;
    padding: 15px;
}
#metrics_contain{
  background-color: #fafafa;
}
#metrics_header{
    background-color: #007cc3;
    border-radius: 5px;
    color: white;
    padding: 3px;
}
#metrics_info_contain>div>div{
    text-align: left;
    background-color: #e9eaec;
    padding: 3px;
    color: grey;
}
#metrics_info_contain>div>div>h6{
    font-weight: 400;
    font-size: 13px;
    color: rgb(106, 127, 156);
}
#metrics_info_contain>div>p{
    text-align: left;
    font-size: 20px;
    color: rgb(66, 66, 66);
    margin-top: 2px;
}
#sales_info_contain{
    background-color: #fafafa;
}
#sales_header{
    background-color: #007cc3;
    border-radius: 5px;
    color: white;
    padding: 5px;
}
#sales_info_unit>div>div{
    text-align: left;
    background-color: #e9eaec;
    padding: 3px;
    color: grey;
}
#sales_info_unit>div>div>h6{
    font-weight: 400;
    font-size: 13px;
    color: rgb(106, 127, 156);
}
#sales_info_unit>div>p{
    text-align: left;
    font-size: 20px;
    color: rgb(66, 66, 66);
    margin-top: 2px;
}
#table_footer p {
    font-size: 13.5px;
    /* color: #444a4f;  */
    color: white;
    font-weight: 500;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 0.4rem;
  }

  #table_footer {
    left: 0;
    bottom: 0;
    z-index: 0;
    position: sticky;
    /* background-color: #89BBE0; */
    /* background-color: white; */
    background-color: #007cc3;
    /* color: #444a4f !important; */
    /* color: white !important; */
    margin-top: 10px;
    height: 30px;
  }
  #tbl_loader{
    height: 300px;
    width: 300px;
    object-fit: cover;
    margin: auto;
    pointer-events: none;
  }
  #main_loader{
    height: 500px;
    width: 500px;
    object-fit: cover;
    margin: auto;
    pointer-events: none;
  }
  #moving_no{
    text-align: left;
    font-size: 20px;
    color: rgb(66, 66, 66);
    margin-top: 2px;
  }
  #filter_container >div p{
    text-align: left;
    font-size: 12px;
    color: grey;
  }
  #header_tab{
    display: flex;
    gap:10px;
  }
  #header_tab>div{
    padding: 7px;
    cursor: pointer;
    border:1px solid #007cc3;
    border-radius: 7px;
    font-size: 14px;
  }