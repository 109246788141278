#tab_contain{
    display: flex;
    justify-content: end;
    position: fixed;
    z-index: 999;
    top:0;
    left:0;
    width: 100%;
    background-color: white;
    margin-top: 8.4vh;
    align-items: center;
}
#tab_contain>div{
    margin-right: 10px;
    padding: 5px;
    border:1px solid #007cc3;
    cursor: pointer;
    border-radius: 5px;
}
/* .noData{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
  } */
  .noData{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    margin-left: 8vw;
  }

  #loader_operation{
    height: 150px;
    width: 600px;
    object-fit: cover;
    margin: auto;
    margin-top: 10em;
    pointer-events: none
  }

  #dropdown_span{
    font-size: 12px;
    color:grey;
  }
/* #loader_pie{
  height: 150px;
  width: 200px;
  object-fit: cover;
  margin: auto;
  margin-top: 10em;
  pointer-events: none
} */
#loader_pie{
  height: 150px;
  width: 200px;
  object-fit: cover;
  margin: auto;
  margin-top: 10em;
  pointer-events: none;
  margin-left: 7vw;
}
#loader_tag_pie{
  height: 200px;
  width: 200px;
  object-fit: cover;
  margin: auto;
  pointer-events: none;
  margin-top: 50px;
}

#graph_contain{
  margin-top: 5%;
 }
 #temp_contain{
  margin-top: 30px;
 }
 #toggle_container{
  border:1px solid white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
  gap:10px;
 }
 #toggle_container>div{
  border: 1px solid #007cc3;
  padding: 5px 10px;
  margin-top: 2vh;
  border-radius: 5px;
  background-color: #007cc3;
  color: white;
 }
 #graph_container {
  /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
 }

 #tooltipBox{
  background: #fff;
   border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
 }

 /* .pieCaption{
  margin-left: 14vw;
} */
/* @media all and (width:1366px){
    .pieCaption{
      margin-left: 10vw !important;
    }
  } */

@media only screen and (width:1366px){
 #graph_contain{
  margin-top: 2%;
 }
 #drop_contain{
  margin-top: 2% !important;
 }
 #temp_contain{
  margin-top: 4%;
 }
}

@media all and (max-width: 1100px ) and (min-width: 500px){
  #tab_contain>div{
  font-size: 12px;
}
}