.teamS6Table thead tr th {
    text-align: left !important;
    min-width: 4vw !important;
}
.teamS6Table tbody tr td:hover {
    color: white !important;
}

.searchableDropdown{
    height: 4.5vh;
    display: flex;
    align-items: center;
    padding: 5px;
}
.teamS6Table thead tr th div{
    text-align: center;
}

@media only screen and (width:1366px){
   
    .searchableDropdown{
        height: 5.5vh;
        display: flex;
        align-items: center;
        padding: 5px;
    }
   }