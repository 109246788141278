#table_contain_service {
  max-height: 70vh;
}
.th_unit,
.th_unit_null,
#td_unit {
  border: 1px solid rgb(201, 201, 201);
  background-color: white;
  padding: 0px !important;
  /* text-align: center; */
  background-color: white !important;
  color: black;
  text-align: center !important;
  vertical-align: bottom;
  width: 200px;
}
.noData {
  padding: 0px !important;
}

/* .th_unit:hover,
.th_unit_null:hover,
#td_unit:hover {
  background-color: white !important;
  color: black;
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: transform 0.1s;
  cursor: default;
} */
.th_unit_null {
  text-align: center !important;
}

#th_unit1,
#td_unit,
#th_unit1_sales {
  border: 1px solid rgb(201, 201, 201);
  vertical-align: middle;
}
#td_unit > h6 {
  text-align: center;
  margin-left: 5px;
  font-weight: 400;
}

#th_unit1 > h6,
#th_unit1_sales > h6 {
  text-align: center;
}

#th_unit1_sales {
  padding: 10px;
}
#footer_div {
  background-color: white;
}
#footer_div > div {
  display: flex;
  align-items: baseline;
  color: black !important;
}
#interested_footer_div>div{
  display: flex;
  align-items: baseline;
  color: black !important;
  border-top: 1px solid rgba(224, 224, 224, 1);
}

.th_unit1 > h5 {
  color: white;
}

thead th {
  background-color: #007cc3 !important;
  color: white !important;
  /* background-color: #89BBE0 !important;
    color: #444a4f !important; */
  padding: 5px !important;
  text-align: left !important;
  text-transform: capitalize !important;
}

tbody td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  text-align: center !important;
}

thead th span {
  justify-content: center !important;
}

thead th span div {
  justify-content: center !important;
  text-transform: capitalize !important;
}

/* #body_content{
    max-height: 100px;
    overflow: auto;
  } */

/* #body_content td:hover {
  background-color: white !important;
  color: black;
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: transform 0.1s;
  cursor: default;
} */



#loader,
#loader2,
#loader_sample {
  height: 300px;
  width: 600px;
  object-fit: cover;
  margin: auto;
  margin-top: 10em;
  pointer-events: none;
}
#leads_loader{
  height: 300px;
  width: 600px;
  object-fit: cover;
  margin: auto;
  margin-top: 5em;
  pointer-events: none;
}
#loader2 {
  margin-top: -1em;
  pointer-events: none;
}
#loader_sample {
  margin-top: 3em;
}

#loader_operation {
  height: 150px;
  width: 600px;
  object-fit: cover;
  margin: auto;
  margin-top: 10em;
  pointer-events: none;
}

#search_btn {
  background-color: aliceblue;
  border: 1px solid aliceblue;
  border-radius: 5px;
}
#headerName {
  display: flex;
  justify-content: left;
}
#dept_drop {
  height: 40px;
  min-width: 200px;
  color:black;
}
#user_drop {
  height: 40px;
  min-width: 220px;
  margin-left: 10px;
  outline: none;
  color:black;
}

#dept_drop:focus,
#user_drop:focus {
  border: 1px solid #007cc3;
  box-shadow: #007cc3 0px 3px 8px;
  outline: none;
}

#head_units {
  text-align: left;
  font-weight: 500;
  font-size: 1em;
  padding-left: 0.7em;
}

#headerIcon {
  color: #444a4f !important;
}

#lab_loader_img {
  height: 125px;
  width: auto;
  margin: auto;
}
/* #lab_container{
    gap: 20px;
  } */
#lab_container > div {
  /* width: 12%; */
  /* border:1px solid #007cc3; */
  border: 10px solid #ffff;
  /* margin:  0% 0.2% 0% 0.2%; */
  padding: 10px;
  /* border-radius: 10px; */
  cursor: pointer;
}

#form_invoice {
  gap: 20px;
}

#dept_drop_invoice,
#user_drop_invoice {
  height: 32px;
  margin-left: 20px;
  border-radius: 5px;
  border: 1px solid #007cc3;
  outline: none;
}
#user_drop_invoice {
  margin-right: 20px;
}
#submit_invoice {
  height: 32px;
  background-color: #007cc3;
  border: 1px solid #007cc3;
  color: white;
  border-radius: 5px;
  margin-left: 20px;
}
.readMoreClassName,
.readLessClassName {
  color: #007cc3;
}
#payment_drop {
  min-width: 220px;
  height: 35px;
  outline: none;
  color:black;
}
#city_drop {
  height: 35px;
  margin-left: 20px;
  outline: none;
  min-width: 220px;
  color:black;
}

#source_drop {
  height: 35px;
  margin-left: 20px;
  outline: none;
  min-width: 220px;
  max-width: 17vw;
  overflow-x: scroll;
  color:black;
}

#table_footer {
  left: 0;
  bottom: 0;
  z-index: 0;
  position: sticky;
  /* background-color: #89BBE0; */
  /* background-color: white; */
  background-color: #007cc3;
  /* color: #444a4f !important; */
  /* color: white !important; */
  margin-top: 10px;
  height: 30px;
}
.transaction_tbl tbody td {
  padding: 5px 10px !important;
}

#table_footer p {
  font-size: 13.5px;
  /* color: #444a4f;  */
  color: white;
  font-weight: 500;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 0.4rem;
}
#first_col {
  position: sticky !important;
  z-index: 9999 !important;
  /* background-color: white; */
  /* background-color: #89BBE0; */
  background-color: #007cc3;
  height: 30px;
  left: 0;
  /* width: 20%; */
}
#first_col_client {
  position: sticky !important;
  z-index: 999 !important;
  /* background-color: white; */
  /* background-color: #89BBE0; */
  background-color: #007cc3;
  height: 30px;
  left: 0;
}
.noData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}
#hub_title {
  color: white;
  text-align: left !important;
  margin-left: 8px !important;
}

/* #second_col{
    position: sticky;
    z-index: 9999;
    left: 0;
    bottom: 0;
    background-color: red;
  } */

#insights_btn {
  width: 120px;
  border: 1px solid #007cc3;
  border-radius: 5px;
  color:black;
}

#header_sales {
  font-weight: bold;
  font-size: 22px;
  color: #007cc3;
  margin-left: 10px;
  margin-top: 5px !important;
}
#toggle_btn {
  height: 40px;
  /* padding-left: 10%; */
}

#table_container table td:first-child {
  position: sticky;
  left: 0;
  background-color: #ddd;
}

.first_header{
  position: sticky !important;
  left: 0 !important;
  background-color: #007cc3 !important;
  z-index: 99999 !important
}
#graph_loader,#purchase_loader{
  height: 300px;
  width: 600px;
  object-fit: cover;
  margin: auto;
  pointer-events: none;
}


#graph_tbl_loader{
   height:35vh;
}

.table_dropdown{
  width: 160px;
  height: 36px;
  font-size: 14px;
  text-align: left;
  background: white;
  border-radius: 5px;
}


/* monthly digest css */

#tbl_Container{
  height:auto !important;
  max-height: 122vh;
  position: relative;

}

#tbl_Container #table_footer{
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 1;
}

#today_sales,#order_value{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 5px;
  min-height: 180px;
  max-height: auto;
  background-color: white;
  border-radius: 5px;
}
#today_sales{
  min-height: 160px;
  max-height: auto;
}

#tbl_loader{
  height: 300px;
  width: 300px;
  object-fit: cover;
  margin: auto;
  pointer-events: none;
}

#value_container{
  background-color: #007cc3;
  color: white;
  padding: 7px;
}
#sales_container{
  background-color: #007cc3;
  color: white;
  padding: 0px 4px;
}
#left_part{
  text-align: left;
}
#left_part_contain{
  margin-top: 2vw;
  line-height: 20px;
}
#right_part{
  text-align: right;
  font-size: 12px;
  color: grey;
}
#min_contain{
  color: grey;
  line-height: 12px;
  font-size: 12px;
  font-weight: 400;
}

/* Salespersonwise Invoice Page css */


#invoice_info_container{
  display: flex;
  gap: 10px;
  justify-content: center;
}
#invoice_info_container>div{
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  text-align: left;
  padding: 10px;
  line-height: 40px;
  border-radius: 5px;
}
#invoice_info_container>div>div{
  background-color: #007cc3;
  color: white;
  padding-left: 5px;
}

/* Billing Report Monthly Digest CSS */

#billing_tabs_container{
 display: flex;
 justify-content: left;
 gap:10px;
}

#billing_tabs_container>div{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 12vw;
  max-width: auto;
  line-height: 30px;
  padding: 0px;
  border-radius: 5px;
  margin-left: 10px;
  padding-bottom: 5px;
}

#billing_tabs_container>div>div{
  background-color: #007cc3;
  color: white;
  /* background-color: #fcd55e; */
  border-radius: 5px 5px 0px 0px;
}

#billing_tabs_container>div>h5{
  margin-top: 10px !important;
}
#billing_tabs_container>div>#billing_count{
  margin-top: 10px !important;
  color: black;
  font-weight: 500;
}
#billing_tabs_container>div>p{
  font-size: 12px;
  color: red;
}


/* Leads Leader Page CSS */

#header_contain>img{
  height: 70px;
  width: auto;
  margin-top: 20px;
  margin: auto;
}
#header_contain>p{
  font-size: 50px;
  margin-top: 20px;
  font-weight: 300;
  color: #bdbdbd;
}
#header_contain>div{
  padding: 10px;
  background-color: #007cc3;
  width: 35%;
  margin: auto;
  margin-top: 20px;
  font-size: 25px;
  font-weight: 700;
  color: white;
}

#leads_loader{
  height: 450px;
  width: 450px;
  object-fit: cover;
  margin: auto;
  pointer-events: none;
}
#leader_right_part{
  display: flex;
  justify-content: center;
  gap:15px;
}
#leader_right_part>div{
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  background-color: #efefef;
  padding: 5px;

}
#leader_right_part>div>p{
  font-size: 13px !important;
  color: grey;
}
#leader_right_part>div>h5{
  font-size: 30px !important;
 font-weight: 400;
 margin-top: 5px !important;
}
/* Sample Report page css */

#sample_count_contain>div{
  line-height: 40px;
}

#sample_count_contain>div>p{
  font-size: 12px;
  color: grey;
}

#sample_count_contain>div>h5,#moving_no{
  font-size: 30px;
  font-weight: 300;
  color: black;
}


/* Overdue Leads Sales CSS */

#overdue_tabsContainer{
  display: flex;
  gap:10px;
  margin-top: 15px;
}

#overdue_tabsContainer>div{
  width: 150px;
  max-width: auto;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  background-color: #eaf1ea;
  padding: 0px;
  text-align: left;
  padding-left: 10px;
  line-height: 27px;
}
#overdue_tabsContainer>div>p{
  font-size: 13px;
  font-weight: 400;
  color: grey;
}
#overdue_tabsContainer>div>h6{
  font-size: 22px;
  font-weight: 400;
  margin-top: 5px !important;
  margin-bottom: 7px !important;
}
#overdue_tabsContainer>div>#overdue_count_moving{
  font-size: 22px;
  font-weight: 400;
  margin-top: 5px !important;
  margin-bottom: 7px !important;
}


/* Purchase Order Page Css */

#po_container{
  width: 150px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  background-color: #eaf1ea;
}
#po_container>div{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
#po_container>div>div>h5,#order_count_moving{
  font-size: 30px;
  font-weight: 500;
}
#po_tab_container{
  gap:10px;
  display: flex;
}
#po_tab_container>div{
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  background-color: #eaf1ea;
  width: 15%;
  max-width: auto;
  padding: 5px;
}
#po_tab_container>div>p{
  font-size: 13px;
  color: grey;
}
#po_tab_container>div>h5{
  font-size: 20px;
}
#filter_container{
display: flex;
justify-content: right;
}

#purchase_loader{
  margin-top: 100px;
}


/* Interested Leads table */

#leads_tbl_Container{
  max-height: 70vh;
  position: relative;

}

#leads_tbl_Container #table_footer{
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 1;

}

#leads_tbl_Container .th_unit>p{
  margin-left: 5px !important;
  font-size: 12px;
}

/* Marketing Performance */

#leads_container{
  height: 30px;
  background-color: white;
  color: black;
  font-size: 14px;
  border-radius: 3px;
  font-weight: 500;
  pointer-events: none;
  padding: 0px 20px;
  /* margin-left: 10px; */
  margin-top: -10px !important;
}

#invoice_container{
  background-color: white;
  color: black;
  font-size: 12px;
  border-radius: 3px;
  font-weight: 500;
  pointer-events: none;
  padding: 0px 5px;
  /* margin-left: 10px; */
}

#total_dash_container{
  background-color: transparent;
  /* border:1px solid white; */
  color: white;
  font-size: 16px;
  border-radius: 3px;
  font-weight: 500;
  pointer-events: none;
  padding: 0px 10px;
  font-weight: bold;
}
#graph_total_container{
  text-align: center;
  background-color: #007cc3;
  color: white;
  font-weight: bold;
  padding: 5px;
  margin-bottom: 5px;
}

/* modal css */

#oh_pop_up{
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
#close_icon{
  cursor: pointer;
}

/* Fnb order details css */

#order_data_units{
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  padding: 0px 7px;
}

/* New customer details page css */

#body_unit_contain{
  padding: 5px !important;
}
#body_unit_contain>p{
  margin: 0px !important;
  text-align: left;
}
#tab_container{
  gap:10px;
  padding-left: 12px;
}
#tab_container>div{
  border:1px solid #007cc3;
  padding: 5px;
  text-align: left;
  border-radius: 7px;
  position: relative;
}
#tab_container>div>h4{
  font-size: 20px;
}
#tab_container>div>p{
  font-size: 13px;
  color: rgb(94, 92, 92);
}
#tab_icon{
  color: grey !important;
  position: absolute;
  top:0;
  right: 0;
  margin-top: 5px;
  margin-right: 5px;
  height: 17px;
}
#leads_header{
  display: flex;
  justify-content: end;
}
#leads_header p{
  text-align: left !important;
  font-size: 12px;
  color: grey;
}
@media all and (max-width: 1400px) and (min-width: 700px) {
  #table_contain_service {
    max-height: 67vh;
  }
  #toggle_btn {
    height: 35px;
  }
  #header_sales {
    font-size: 20px;
  }
  #btn_bde{
    padding : 10px 5px;
    font-size: 12px;
  }
  #dateContainer{
    margin-left: -5vw;
  }
#leads_tbl_Container{
  max-height: 66vh !important;
}
}
@media all and (max-width: 1400px) and (min-width: 1200px) {
  #toggle_btn {
    height: 40px;
    padding-left: 16%;
  }
}

@media all and (max-width: 1100px) and (min-width: 600px) {
  #lab_container > div {
    font-size: 12px;
  }
  #toggle_btn {
    height: 35px;
    padding-left: 0%;
  }
}

@media all and (max-width: 700px) and (min-width: 50px) {
  #btn_bde{
    padding : 25px 5px;
  }
}
