.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* #table_s6_category_tbl tbody td,
#corporate_tbl tbody td,
#salesperson_order_tbl tbody td,
#purchase_tbl tbody td,
#billing_tbl tbody td,
#created_by_tbl tbody td,
#client_type_tbl tbody td {
  font-size: 12px !important;
} */
table > tfoot > tr > td > div > div > div {
  display: flex !important;
  align-items: baseline !important;
}
table > tfoot > tr > td {
  padding: 2px 4px !important;
}

table tbody td{
  font-size: 12px !important;
}

body,
html {
  font-family: "Roboto", sans-serif;
}
.opertion_tbl .css-1ygcj2i-MuiTableCell-root,
.opertion_tbl .css-1bigob2 {
  text-align: center !important;
}

#table_s6 .css-1ex1afd-MuiTableCell-root,
#table_s6 .css-q34dxg,
#client_s6 .css-1ex1afd-MuiTableCell-root,
#client_s6 .css-q34dxg,
#client_type_tbl .css-1ex1afd-MuiTableCell-root,
#client_type_tbl .css-q34dxg,
#table_s6_category_tbl .css-1ex1afd-MuiTableCell-root,
#table_s6_category_tbl .css-q34dxg {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43 !important;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: center !important;
  padding: 1px 0px !important;
  color: rgba(0, 0, 0, 0.87);
}

/* #table_s6 .css-1ex1afd-MuiTableCell-root:hover,
#table_s6 .css-q34dxg:hover,
#client_s6 .css-1ex1afd-MuiTableCell-root:hover,
#client_s6 .css-q34dxg:hover,
#table_s6_category_tbl .css-1ex1afd-MuiTableCell-root:hover,
#table_s6_category_tbl .css-q34dxg:hover,
#client_type_tbl .css-1ex1afd-MuiTableCell-root:hover,
#client_type_tbl .css-q34dxg:hover
 {
  cursor: pointer;
  color: white !important;
  border-radius: 2px;
  background-color: #333 !important;
  transition: transform 0.1s;
  z-index: 99999;
} */

/* Searchable dropdown from funnel */
.p-dropdown-clear-icon,
.p-dropdown-filter-icon,
.p-dropdown-filter-clear-icon {
  left: 87% !important;
}
.p-dropdown-panel,
.p-component,
.p-ripple-disabled,
.p-connected-overlay-enter-done {
  max-width: 17vw !important;
  width: 16.2vw;
  /* max-width: 240px !important; */
}
.p-dropdown-items {
  margin-bottom: 0px !important;
  padding-left: 0px !important;
}
/* .p-dropdown-label, .p-inputtext, .p-placeholder{
  padding: 6px 4px 6px 4px !important;
} */
.p-dropdown-empty-message {
  background-color: white !important;
  padding: 1px 8px 1px 8px !important;
}

.p-dropdown-items-wrapper {
  display: flex;
}

/* date range picker */

.rs-picker-popup.rs-picker-popup-daterange {
  z-index: 999 !important;
}
#customer_dateRange .rs-input-group.rs-input-group-inside{
  height: 38px !important;
}
.date_contain {
}
/* .rs-input-group{
  width: 100% !important;
  border: 1px solid red !important;
} */

/* .date_contain .rs-picker-popup.rs-picker-popup-daterange{
  z-index: 99999 !important;
} */

/* table css */

/* #table_container table {
  border-collapse: separate;
  border-spacing: 0;
}

#table_container td,
#table_container th {
  margin: 0;
  white-space: nowrap;
  border-top-width: 0px;
}

#table_container {
  overflow-x: scroll;
  overflow-y: visible;
  padding: 0;
}

#table_container .headcol {
  position: absolute;

  left: 10;
  top: auto;
  border-top-width: 1px;

  margin-top: -1px;

}
.long {
  background: yellow;
  letter-spacing: 1em;
} */

/* .css-1fbgwj4-MuiBarElement-root {
  width: 70px !important;
}
.css-1gakkqb-MuiResponsiveChart-container{
  width: 100vw !important;
  overflow: auto;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  width: 60% !important;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8.5px 14px !important;
}
.css-1uvydh2 {
  padding: 8.5px 14px !important;
}

/* chart */
/* .recharts-surface{
  height: 400px !important;
  } */

/* .tss-qbo1l6-MUIDataTableToolbar-actions {
  display: flex!important;
  flex-direction: row-reverse!important;
  align-items: end!important;
}
.tss-qbo1l6-MUIDataTableToolbar-actions{
  display: flex!important;
  flex-direction: row-reverse!important;
  align-items: end!important;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 8.5px 14px!important;  
}
.css-1uvydh2 {
  padding: 8.5px 14px!important;
} */

.css-kl65in-MuiToolbar-root,
.css-1p68svw {
  background-color: white !important;
  color: #007cc3 !important;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper,
.css-1l8j5k8 {
  /* background-color: #aedcf7 !important; */
  /* background-color: #89BBE0 !important; */
  background-color: #007cc3 !important;
  /* color: #444a4f !important; */
}

/* thead th {
  background-color: #007cc3 !important;
  color: white !important;
  padding: 1px !important;
  text-align: left !important;

} */

tbody td {
  padding: 0px !important;
  text-align: center !important;
}
tbody td p {
  margin: 3% auto 3% 5% !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
body.swal2-toast-shown .swal2-container.swal2-top-end,
body.swal2-toast-shown .swal2-container.swal2-top-right {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
  z-index: 9999 !important;
}

/* drop down */

/*  , .css-r8u8y9 */

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper,
.css-pwxzbm {
  margin-top: 0% !important;
  height: auto !important;
  max-height: 400px !important;
  position: absolute !important;
}
.tss-ynxllk-MUIDataTableFilter-root {
  position: relative !important;
}

/* table header part */

.tss-tlx3x1-MUIDataTableToolbar-root {
  background-color: #007cc3;
  color: white;
  border-bottom: 1px solid white;
  /* background-color: #89BBE0; 
     color: #444a4f;
    border-bottom: 1px solid #444a4f; */
}

thead th span button {
  min-width: 25px !important;
  padding: 5px 0px !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h3,
h4,
h5,
h6 {
  margin: auto !important;
}

.css-i4bv87-MuiSvgIcon-root,
.css-slyssw,
.css-1yxmbwk {
  /* color: white !important; */
  /* background-color: #89BBE0 !important; */
  /* color: #444a4f !important; */
  color: white !important;
}

.css-1yxmbwk {
  color: white !important;
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input,
.css-mnn31 {
  color: white !important;
}

tfoot .css-i4bv87-MuiSvgIcon-root,
.css-slyssw {
  color: #444a4f !important;
}
/* tfoot>tr>td>div>div .css-i4bv87-MuiSvgIcon-root{
  color: grey !important;
} */
.MuiTablePagination-actions > button {
  color: black !important;
}
.css-1ex1afd-MuiTableCell-root {
  line-height: 1.35 !important;
}
.tss-1h5wt30-MUIDataTableSearch-searchIcon {
  color: #444a4f !important;
  /* color:white !important; */
}
.css-1s00m2z,
.tss-1h5wt30-MUIDataTableSearch-searchIcon {
  /* color: #444a4f !important; */
  color: white !important;
}

#client_type_tbl .tss-1cdcmys-MUIDataTable-responsiveBase {
  max-height: 58vh !important;
}

/* year picker part */

/* .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,.css-1uvydh2{
  
  padding: 8.5px 14px !important;
  color: white !important;
}

.css-bkzwk2-MuiStack-root  ,.css-19wm042 {
  border:1px solid white !important;
  width: 55%;
  padding-top: 0px;
  margin: 0px auto;
  padding-top: 0px !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root ,.css-p0rm37{
  color: #fff !important;
  line-height: 0.8 !important;
  font-size: 1.1rem !important;
} */

/* .css-1ygcj2i-MuiTableCell-root{
  text-align: center !important
} */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(143, 138, 138, 0.3);
  background-color: #eee;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #2a3140;
}

::-webkit-scrollbar-thumb {
  background-color: #2a3140;
}

.footer_div .css-i4bv87-MuiSvgIcon-root,
.css-slyssw {
  color: #444a4f !important;
}
.MuiChartsTooltip-root {
  max-width: 300px !important;
  padding-right: 20px;
}

.location_graph .MuiChartsTooltip-root {
  width: 300px !important;
}

.recharts-surface {
  height: 400px !important;
}
#nps_graph .recharts-surface {
  height: 600px !important;
  margin-left: 7%;
}
.recharts-surface:focus {
  outline: none !important;
}
.recharts-layer:focus {
  outline: none !important;
}

.dept_head {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.css-2tndiz .MuiChartsReferenceLine-line {
  stroke: green !important;
}

/* 
  .recharts-surface{
    height: 400px !important;
    margin-top: 0px !important;
    }
    @media only screen and (width:1366px){
      .recharts-surface{
        margin-top: -70px !important;
      }
    } */
#order_tbl {
  max-height: 70vh;
}
#qc_tbl table tbody td {
  padding: 1px !important;
}

#lead_contain tspan,
#all_lead_contain tspan {
  font-weight: bold !important;
}

/* #billing_tbl table thead th{
  background-color: #fcd55e !important;
  color: black !important;
} */

/* #dark_tbl tbody td,#dark_tbl thead th,#dark_tbl thead th h6,#dark_tbl .tss-tlx3x1-MUIDataTableToolbar-root{
   background-color: black !important;
   color: grey !important;
}
#dark_tbl{
  border:1px solid grey !important;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.tss-bd2lw8-MUIDataTableHeadCell-sortActive,
.css-dx096b.Mui-active .MuiTableSortLabel-icon,
.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active
  .MuiTableSortLabel-icon {
  color: white !important;
}
.tss-1cdcmys-MUIDataTable-responsiveBase {
  max-height: 63vh !important;
}
#table_s6_category_tbl .tss-1cdcmys-MUIDataTable-responsiveBase {
  max-height: 57vh !important;
}

#corporate_tbl .tss-1cdcmys-MUIDataTable-responsiveBase {
  max-height: 60vh !important;
}
#created_by_tbl .tss-1cdcmys-MUIDataTable-responsiveBase {
  max-height: 40vh !important;
}
#analysis_tbl .tss-1cdcmys-MUIDataTable-responsiveBase{
  max-height: 60vh !important; 
} 
#salesperson_order_tbl .tss-1cdcmys-MUIDataTable-responsiveBase {
  max-height: 67.5vh !important;
}

#client_s6 .tss-1cdcmys-MUIDataTable-responsiveBase {
  max-height: 58vh !important;
}

#billing_tbl .tss-1cdcmys-MUIDataTable-responsiveBase {
  max-height: 50vh !important;
}

#purchase_tbl .tss-1cdcmys-MUIDataTable-responsiveBase {
  max-height: 43vh !important;
}

/* .css-pdct74-MuiTablePagination-selectLabel{
  margin-bottom: 0px !important;
}
.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar{
  min-height: 35px !important;
} */

@media all and (max-width: 1900px) {
  #table_s6_category_tbl .tss-1cdcmys-MUIDataTable-responsiveBase {
    max-height: 61vh !important;
  }
}

@media all and (max-width: 4000px) and (min-width: 1800px) {
  .tss-1cdcmys-MUIDataTable-responsiveBase {
    max-height: 74vh !important;
  }
  #client_s6 .tss-1cdcmys-MUIDataTable-responsiveBase {
    max-height: 66.5vh !important;
  }
  #order_tbl {
    max-height: 81vh;
  }

  #table_s6_category_tbl tbody td tbody td {
    font-size: 0.875rem !important;
  }
  /* #nps_graph .recharts-surface{
  height: 400px!important;
  margin-left: 5% !important;
  margin-top: 10%;
} */
}

@media all and (max-width: 1400px) and (min-width: 1000px) {
  .table_report_insight .tss-1cdcmys-MUIDataTable-responsiveBase {
    max-height: 50vh !important;
  }
  #client_s6 .tss-1cdcmys-MUIDataTable-responsiveBase {
    max-height: 52vh !important;
  }
  #nps_graph .recharts-surface {
    height: 400px !important;
    margin-left: 5% !important;
    margin-top: 10%;
  }

  #table_s6_category_tbl .tss-1cdcmys-MUIDataTable-responsiveBase {
    max-height: 56vh !important;
  }
  #client_type_tbl .tss-1cdcmys-MUIDataTable-responsiveBase {
    max-height: 55vh !important;
  }
  #qc_tbl .tss-1cdcmys-MUIDataTable-responsiveBase {
    /* height: 65vh !important; */
  }
  #salesperson_order_tbl .tss-1cdcmys-MUIDataTable-responsiveBase {
    max-height: 68vh !important;
  }
  #analysis_tbl .tss-1cdcmys-MUIDataTable-responsiveBase{
    max-height: 54vh !important;
  } 
}

@media only screen and (width: 1366px) {
  .css-1sfpt6l-MuiResponsiveChart-container > svg,
  .css-1210fj7 > svg {
    width: 80% !important;
    height: 100% !important;
    margin-top: 0% !important;
    margin-left: 20% !important;
  }
}

@media all and (max-width: 1100px) and (min-width: 500px) {
  .css-s0svql-MuiResponsiveChart-container > svg,
  .css-guitj4 > svg {
    width: 53% !important;
    height: 100% !important;
    margin-left: -45% !important;
  }
  .css-1sfpt6l-MuiResponsiveChart-container > svg,
  .css-1210fj7 > svg {
    width: 53% !important;
    height: 100% !important;
    margin-top: -20% !important;
    margin-left: -30% !important;
  }
}
