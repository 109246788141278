#tabsDiv{
    /* display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3%;
    width: 94%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #89BBE0 !important;
    /* margin-bottom: 20px; */
}
.nps_tabs{
    background-color: #89BBE0;
    color: #444a4f;
    margin: 8px;
    padding: 10px;
    width: 100%;
    border-radius: 10px;
}
.monthPicker{
    padding: 0px;
    /* margin-left: -300px;
    margin-top: 22px; */
    height: 36px;
    width: 165px;
    margin-top: 10px;
}
#loader{
    height: 200px;
    width: 500px;
    object-fit: cover;
    margin: auto;
    margin-top: 10em;
    pointer-events: none
  }
  .submitBtn{
    margin-left: 20%;
    margin-top: -40px;
  }




  .containerDiv{
    /* margin-top: 1%; */
    /* margin-bottom: 2%; */
    /* margin-left: 1%; */
    /* display: flex; */
    /* width: 99%; */
  }
  .tabs_left{
    background: linear-gradient(180deg, #2E85C5 0%, rgba(45.75, 132.54, 196.76, 0) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .divIcons{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
  .mainTabDiv{
    display: flex;
    width: 85%;
    height: 110px;
    margin-top: 15px;
    background: #89BBE0;
  }
  .tabsCount{
    background-color: #89BBE0;
     /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  }
  .npsCount{
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    font-weight: 900;
  }
  .noData{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
  }

  
 




  /* height: 50px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  margin-top: 9%;
  margin-left: 26%; */


  /* position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: black;
  text-align: center;
  z-index: 1; */